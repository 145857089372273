import { MouseEvent, useEffect } from "react";
import "./landingComponent.scss"
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import FooterComponent from "../footer/FooterComponent";

const LandingComponent = (props) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const btnLoginUserHandler = async (event: MouseEvent) => {
    navigate("/user/profile");
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user/profile");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && (
        <main>
          <div className="main-content-container w-100">
            <div className="grid-container">
              <div className="row w-100 h-100 align-items-center">
                <div className="col-lg-3 d-none d-md-block">
                  {/* empty */}
                </div>
                <div className="col-lg-9">
                  <div className="login-container">
                    <div className="logoContainer">
                      <a href="https://www.edify.ac">
                        <img
                          src={process.env.PUBLIC_URL + "/images/edify-logo-negative.png"}
                          className="logo-login mt-60"
                          alt="edify logo"
                        />
                      </a>
                    </div>
                    <div className="loginButtonContainer">
                      <Button variant="secondary" onClick={btnLoginUserHandler}>
                        Login
                      </Button>
                    </div>
                    <div className="registerAccountContainer">
                      <p>
                        Don't have an account?{" "}
                        <span className="text-underline">
                          <a href="/user/register">Register Here</a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterComponent />
        </main>
      )}
    </>
  );
};

export default LandingComponent;
