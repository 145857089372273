import { useEffect } from "react";
import {
  userUpdateStarted,
  userUpdateSuccess,
  userUpdateError,
} from "../../state/user/userActions";
import { IUser } from "../../interfaces/IUser";
import { useDispatch } from "react-redux";
import { IServiceResponse } from "../../interfaces/IServiceReponse";
import AccountService from "../../services/AccountService";

const VirtualAvatarComponent = (props) => {
  const dispatch = useDispatch();
  const account: IUser = props.account;

  const subscribe = async (event) => {
    if (event.origin === "https://edify.readyplayer.me") {
      if (event.data.substr(event.data.lastIndexOf(".") + 1) === "glb") {
        await updateUserAvatar(event.data);
      }
    }
  };

  const updateUserAvatar = async (avatarUrl: string) => {
    if (avatarUrl) {
      const updatedAccount: IUser = account;
      updatedAccount.AvatarUrl = avatarUrl;
      dispatch(userUpdateStarted());
      try {
        const accountService = new AccountService(account.Token);
        const response = await accountService.updateUser(updatedAccount);
        const serviceResponse: IServiceResponse = response.data;
        if (serviceResponse.success) {
          props.updateAccount(updatedAccount);
          dispatch(userUpdateSuccess(updatedAccount));
        } else {
          dispatch(userUpdateError("Error updating Avatar URL!"));
        }
      } catch (error) {
        dispatch(userUpdateError(`Error updating Avatar URL: ${error}`));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", subscribe);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        id="saved-alert"
        className="alert alert-success alert-dismissible fade hide"
        role="alert"
      >
        <strong>Your avatar has been saved.</strong>
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="vr-avatar-frame">
        <iframe
          id="vr-iframe"
          title="vr-avatar-frame"
          src="https://edify.readyplayer.me/avatar?frameApi"
          className="vr-editor"
          allow="camera *; microphone *"
        ></iframe>
      </div>
    </>
  );
};

export default VirtualAvatarComponent;
