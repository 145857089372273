import ProfileImageComponent from "./ProfileImageComponent";
import LogoutButton from "../logoutButton/LogoutButton";

const SidePanelComponent = ({ account }) => {
  return (
    <>
      <aside id="sidebar" className="sidebar">
        <div className="sidebar__holder">
          <ul className="nav flex-column">
            <li className="sidebar-user-profile">
              <div className="sidebar-avatar">
                <ProfileImageComponent account={account} />
              </div>
              <div className="sidebar-username">
                <span className="sibebar-displayname">
                  {account.FirstName} {account.LastName}
                </span>
                <a className="sidebar-profile-link" href="/user/profile/">
                  Profile
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/user/profile/">
                Profile
              </a>
            </li>
            <li className="nav-item">
              <span>
                <LogoutButton className="logout-btn nav-link"/>
              </span>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SidePanelComponent;
