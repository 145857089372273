import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { IServiceResponse } from "../interfaces/IServiceReponse";

const BASE_URL_: string = process.env.REACT_APP_API_BASE_URL!;
const CLIENT_APP_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID!;

class ApplicationService extends HttpService {
  constructor(accessToken: string) {
    super(BASE_URL_, accessToken);
  }

  getApplications(
    appId: string,
    sortBy: string,
    latest: boolean
  ): Promise<AxiosResponse<IServiceResponse>> {
    return this.http.get("v1/applications", {
      headers: { "X-Edify-ClientAuthorization": CLIENT_APP_ID },
      params: { appId: appId, sortBy: sortBy, latest: latest },
    });
  }
}

export default ApplicationService;
