import "./fullPageLoader.scss";

const FullPageLoader = () => {
    return (
        <div data-testid="full-page-loader" className="fp_loading">
            <img alt="Edify"
                src={process.env.PUBLIC_URL + "/images/edify-logo-negative.png"}
                height="68"></img>
            <div className="loader mt-3"></div>
        </div>
    );
}

export default FullPageLoader;
