import './App.css';
import React, {Component} from 'react';
import {Routes, Route} from 'react-router-dom';
import RegisterComponent from './components/register/RegisterComponent';
import ProfileContainerComponent from './components/profile/ProfileContainerComponent';
import LandingComponent from './components/landing/LandingComponent';
import ProtectedComponent from './components/auth/ProtectedComponent';
import RegisterSuccessComponent from './components/register/RegisterSuccessComponent';

class App extends Component {

    render(): React.ReactNode {
        return (
            <Routes>
                <Route path='/' element={<LandingComponent />} />
                <Route path='/user/register/success' element={<RegisterSuccessComponent />} />
                <Route path='/user/register' element={<RegisterComponent/>}/>
                <Route path='/user/profile' element={<ProtectedComponent component={ProfileContainerComponent} />}/>
            </Routes>
        );
    }
}

export default App;
