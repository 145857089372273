import { AxiosResponse } from "axios";
import HttpService from "./HttpService";
import { IServiceResponse } from "../interfaces/IServiceReponse";
import { IUser } from "../interfaces/IUser";

const BASE_URL_: string = process.env.REACT_APP_API_BASE_URL!;

class AccountService extends HttpService {
  constructor(accessToken: string) {
    super(BASE_URL_, accessToken);
  }

  getUser(): Promise<AxiosResponse<IServiceResponse>> {
    return this.http.get("v1/account", {});
  }

  updateUser(user: IUser): Promise<AxiosResponse<IServiceResponse>> {
    return this.http.patch("v1/account", {
      firstName: user.FirstName,
      lastName: user.LastName,
      avatarUrl: user.AvatarUrl,
      profilePictureUrl: user.ProfilePictureUrl,
    });
  }

  getUploadToken(fileName: string): Promise<AxiosResponse<IServiceResponse>> {
    return this.http.post("v1/account/picture", {
      fileName: fileName,
    });
  }
}

export default AccountService;
