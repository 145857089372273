import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  registerUserStarted,
  registerUserError,
  registerUserSuccess,
} from "../../state/user/userActions";
import { Button } from "react-bootstrap";
import HeaderComponent from "../header/HeaderComponent";
import FooterComponent from "../footer/FooterComponent";
import { CAPTCHA_ACTION_REGISTER } from "../../constants/userConstants";
import { IRegisterUser } from "../../interfaces/IRegisterUser";
import UserService from "../../services/UserService";
import { IServiceResponse } from "../../interfaces/IServiceReponse";
import { AxiosError } from "axios";
import useScript from "../../hooks/useScript";

const RegisterComponent = () => {
  const [registerState, setRegisterState] = useState({
    firstname: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    consent: false,
    reCaptchaToken: "",
  });

  type Error = {
    field: string;
    error: string;
  };

  const [errorState, setErrorState] = useState<Error[]>([]);
  const [togglePasswordVis, setTogglePasswordVis] = useState(false);
  const [toggleConfirmVis, setToggleConfirmVis] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setTogglePasswordVis(!togglePasswordVis);
  };

  const toggleConfirmVisibility = () => {
    setToggleConfirmVis(!toggleConfirmVis);
  };

  const handleFirstnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterState({ ...registerState, firstname: event.target.value });
  };

  const handleSurnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterState({ ...registerState, surname: event.target.value });
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterState({ ...registerState, email: event.target.value });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterState({ ...registerState, password: event.target.value });
  };

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRegisterState({ ...registerState, confirmPassword: event.target.value });
  };

  const handleConsentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterState({ ...registerState, consent: event.target.checked });
  };

  const btnRegisterUserHandler = (event: MouseEvent) => {
    let reCaptchaToken = "";

    grecaptcha.ready(function () {
      grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!, {
          action: CAPTCHA_ACTION_REGISTER,
        })
        .then(async function (token) {
          reCaptchaToken = token;

          const user: IRegisterUser = {
            email: registerState.email,
            password: registerState.password,
            firstname: registerState.firstname,
            surname: registerState.surname,
            consent: registerState.consent,
            reCaptchaToken: reCaptchaToken,
          };

          await startRegisterUser(user);
        });
    });
  };

  const startRegisterUser = async (user: IRegisterUser) => {
    setDisabled(true);
    setErrorState([]);
    if (validateInput()) {
      await dispatch(registerUserStarted());
      try {
        const service = new UserService();
        const response = await service.registerUser(user);
        const serviceResponse: IServiceResponse = response.data;
        if (serviceResponse.success) {
          //redirect to success page
          console.log(serviceResponse.data);
          navigate("/user/register/success");
        }
        await dispatch(registerUserSuccess());
      } catch (err) {
        let axiosErr = err as AxiosError;
        const serviceResponse: IServiceResponse = axiosErr.response
          ?.data as IServiceResponse;
        mapErrorToField(serviceResponse);
        await dispatch(registerUserError(err));
      }
    }
    setDisabled(false);
  };

  const validateInput = () => {
    if (
      registerState.confirmPassword === registerState.password &&
      registerState.consent
    ) {
      return true;
    } else if (!registerState.consent) {
      let error: Error = {
        field: "general",
        error: "You can only register by agreeing to data storage.",
      };
      setErrorState((errorState) => [...errorState, error]);
    }
    return false;
  };

  const mapErrorToField = (response: IServiceResponse) => {
    if (response.errors) {
      let errors: Error[] = response.errors.map((e) => ({
        field: e.field.toLowerCase(),
        error: e.error.toLowerCase(),
      }));
      setErrorState(errors);
    } else {
      setErrorState((errorState) => [
        ...errorState,
        {
          field: "general",
          error: "Could not create an account with the provided credentials.",
        },
      ]);
    }
  };

  // Add Google Recaptcha
  const greptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!;
  const greptchaScriptUrl = `https://www.google.com/recaptcha/api.js?render=${ greptchaSiteKey }`;
  useScript("greptchaScriptContainer", greptchaScriptUrl, false);

  return (
    <>
      <div id="greptchaScriptContainer">
      </div>
      <HeaderComponent></HeaderComponent>
      <main className="main-register-container mt-67">
        <div className="container-fluid  px-5">
          <div className="account-pages">
            <div className="row">
              <div className="col-md-5">
                <form
                  action="/User/Register?Length=5"
                  method="post"
                  noValidate={false}
                >
                  <h1 className="registerHeading">Get Started</h1>
                  <p className="promo-text">
                    Want to take Edify for a spin? Awesome! There's free, no
                    obligation trial access to the Beta right this way.
                  </p>
                  <p className="promo-text">
                    Simply create a user account below then click the
                    'Download Edify' button once you've signed in, and then
                    you're off!
                  </p>
                  <p className="promo-text">
                    *edify BETA download is currently available for Windows
                    only.
                  </p>
                  <input
                    name="__RequestVerificationToken"
                    type="hidden"
                    defaultValue="T-sGOiAfRe9GfY1hlGaIXQ3cA_8SVSFYq03VkrLiu9u6aqP3ly0TNucgpRZD5q94LbW4Sj6Mny6Ats-WFC3xI7q1dKlV-YxM4kzVvDrQaHI1"
                  />
                  <div className="form-group">
                    <label className="signInLabel" htmlFor="FirstName">
                      First Name
                    </label>
                    <input
                      disabled={disabled}
                      className={
                        errorState.filter(
                          (error) => error.field === "firstname"
                        ).length === 0
                          ? "form_input"
                          : "form_input  input-validation-error"
                      }
                      data-val="true"
                      data-val-required="The First Name field is required."
                      id="FirstName"
                      name="FirstName"
                      type="text"
                      value={registerState.firstname}
                      onChange={handleFirstnameChange}
                      style={{
                        backgroundImage:
                          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=")',
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                      }}
                    />
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-for="FirstName"
                        data-valmsg-replace="true"
                      >
                        {
                          errorState.filter(
                            (error) => error.field === "firstname"
                          )[0]?.error
                        }
                      </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="signInLabel" htmlFor="LastName">
                      Surname
                    </label>
                    <input
                      disabled={disabled}
                      className={
                        errorState.filter(
                          (error) => error.field === "lastname"
                        ).length === 0
                          ? "form_input"
                          : "form_input  input-validation-error"
                      }
                      data-val="true"
                      data-val-required="The Surname field is required."
                      id="LastName"
                      name="LastName"
                      type="text"
                      value={registerState.surname}
                      onChange={handleSurnameChange}
                    />
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-for="LastName"
                        data-valmsg-replace="true"
                      >
                        {
                          errorState.filter(
                            (error) => error.field === "lastname"
                          )[0]?.error
                        }
                      </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="signInLabel" htmlFor="EmailAddress">
                      Email Address
                    </label>
                    <input
                      disabled={disabled}
                      className={
                        errorState.filter(
                          (error) => error.field === "email"
                        ).length === 0
                          ? "form_input"
                          : "form_input  input-validation-error"
                      }
                      data-val="true"
                      data-val-email="The Email Address field is not a valid e-mail address."
                      data-val-required="The Email Address field is required."
                      id="EmailAddress"
                      name="EmailAddress"
                      type="text"
                      value={registerState.email}
                      onChange={handleEmailChange}
                    />
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-for="EmailAddress"
                        data-valmsg-replace="true"
                      >
                        {
                          errorState.filter(
                            (error) => error.field === "email"
                          )[0]?.error
                        }
                      </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="signInLabel" htmlFor="Password">
                      Password
                    </label>
                    <input
                      disabled={disabled}
                      className={
                        errorState.filter(
                          (error) => error.field === "password"
                        ).length === 0
                          ? "form_input"
                          : "form_input  input-validation-error"
                      }
                      data-val="true"
                      data-val-password="Your password must be 8 characters long."
                      data-val-password-min="8"
                      data-val-required="The Password field is required."
                      id="Password"
                      name="Password"
                      type={togglePasswordVis ? "text" : "password"}
                      value={registerState.password}
                      onChange={handlePasswordChange}
                      style={{
                        backgroundImage:
                          "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABKRJREFUWAnNl0tsVGUUxzvTTlslZUaCloZHY6BRFkp9sDBuqgINpaBp02dIDImwKDG6ICQ8jBYlhg0rxUBYEALTpulMgBlqOqHRDSikJkZdGG0CRqAGUuwDovQ1/s7NPTffnTu3zMxGvuT2vP7n8Z3vu+dOi4r+5xUoJH8sFquamZmpTqfTVeIfCARGQ6HQH83NzaP5xsu5gL6+vuVzc3NdJN1Kkhd8Ev1MMYni4uJjra2tt3wwLvUjCxgYGFg8Pj7+MV5dPOUub3/hX0zHIpFId0NDw6Q/jO4tZOzv76+Znp6+AOb5TBw7/YduWC2Hr4J/IhOD/GswGHy7vb39tyw2S+VbAC1/ZXZ29hKoiOE8RrIvaPE5WvyjoS8CX8sRvYPufYpZYtjGS0pKNoD/wdA5bNYCCLaMYMMEWq5IEn8ZDof3P6ql9pF9jp8cma6bFLGeIv5ShdISZUzKzqPIVnISp3l20caTJsaPtwvc3dPTIx06ziZkkyvY0FnoW5l+ng7guAWnpAI5w4MkP6yy0GQy+dTU1JToGm19sqKi4kBjY+PftmwRYn1ErEOq4+i2tLW1DagsNGgKNv+p6tj595nJxUbyOIF38AwipoSfnJyMqZ9SfD8jxlWV5+fnu5VX6iqgt7d3NcFeUiN0n8FbLEOoGkwdgY90dnbu7OjoeE94jG9wd1aZePRp5AOqw+9VMM+qLNRVABXKkLEWzn8S/FtbdAhnuVQE7LdVafBPq04pMYawO0OJ+6XHZkFcBQA0J1xKgyhlB0EChEWGX8RulsgjvOjEBu+5V+icWOSoFawuVwEordluG28oSCmXSs55SGSCHiXhmDzC25ghMHGbdwhJr6sAdpnyQl0FYIyoEX5CeYOuNHg/NhvGiUUxVgfV2VUAxjtqgPecp9oKoE4sNnbX9HcVgMH8nD5nAoWnKM/5ZmKyySRdq3pCmDncR4DxOwVC64eHh0OGLOcur1Vey46xUZ3IcVl5oa4OlJaWXgQwJwZyhUdGRjqE14VtSnk/mokhxnawiwUvsZmsX5u+rgKamprGMDoA5sKhRCLxpDowSpsJ8vpCj2AUPzg4uIiNfKIyNMkH6Z4hF3k+RgTYz6vVAEiKq2bsniZIC0nTtvMVMwBzoBT9tKkTHp8Ak1V8dTrOE+NgJs7VATESTH5WnVAgfHUqlXK6oHpJEI1G9zEZH/Du16leqHyS0UXBNKmeOMf5NvyislJPB8RAFz4g8IuwofLy8k319fUP1EEouw7L7mC3kUTO1nn3sb02MTFxFpsz87FfJuaH4pu5fF+reDz+DEfxkI44Q0ScSbyOpDGe1RqMBN08o+ha0L0JdeKi/6msrGwj98uZMeon1AGaSj+elr9LwK9IkO33n8cN7Hl2vp1N3PcYbUXOBbDz9bwV1/wCmXoS3+B128OPD/l2LLg8l9APXVlZKZfzfDY7ehlQv0PPQDez6zW5JJdYOXdAwHK2dGIv7GH4YtHJIvEOvvunLCHPPzl3QOLKTkl0hPbKaDUvlTU988xtwfMqQBPQ3m/4mf0yBVlDCSr/CRW0CipAMnGzb9XU1NSRvIX7kSgo++Pg9B8wltxxbHKPZgAAAABJRU5ErkJggg==&quot;)",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                      }}
                    />
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-replace="true"
                      >
                        {
                          errorState.filter(
                            (error) => error.field === "password"
                          )[0]?.error
                        }
                      </span>
                    </span>
                    <div
                      onClick={togglePasswordVisibility}
                      className="password-icon"
                    >
                      <div
                        className="togglePasswordShow"
                        style={togglePasswordVis ? { display: "none" } : {}}
                      >
                        <svg
                          width="31px"
                          height="31px"
                          viewBox="0 0 31 31"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Templates"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              id="Contact-Us-template"
                              transform="translate(-455.000000, -411.000000)"
                              stroke="#141B4D"
                              strokeWidth="2"
                            >
                              <g
                                id="Icons/Show/On"
                                transform="translate(456.000000, 412.000000)"
                              >
                                <g
                                  id="eye"
                                  transform="translate(0.181818, 0.818182)"
                                >
                                  <path
                                    d="M13.6742424,-6.75015599e-14 C22.3760331,-6.75015599e-14 27.3484848,9.94490358 27.3484848,9.94490358 C27.3484848,9.94490358 22.3760331,19.8898072 13.6742424,19.8898072 C4.97245179,19.8898072 1.0658141e-14,9.94490358 1.0658141e-14,9.94490358 C1.0658141e-14,9.94490358 4.97245179,-6.75015599e-14 13.6742424,-6.75015599e-14 Z M13.6742424,6.21556474 C11.6145855,6.21556474 9.94490358,7.88524661 9.94490358,9.94490358 C9.94490358,12.0045606 11.6145855,13.6742424 13.6742424,13.6742424 C15.7338994,13.6742424 17.4035813,12.0045606 17.4035813,9.94490358 C17.4035813,7.88524661 15.7338994,6.21556474 13.6742424,6.21556474 Z"
                                    id="Combined-Shape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div
                        className="togglePasswordHide"
                        style={
                          !togglePasswordVis ? { display: "none" } : {}
                        }
                      >
                        <svg
                          width="31px"
                          height="31px"
                          viewBox="0 0 31 31"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Templates"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Contact-Us-template"
                              transform="translate(-401.000000, -407.000000)"
                              stroke="#141B4D"
                            >
                              <g
                                id="Group-2"
                                transform="translate(401.000000, 407.000000)"
                              >
                                <g
                                  id="Icons/Show/On-Copy"
                                  transform="translate(2.000000, 5.000000)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                >
                                  <g
                                    id="eye"
                                    transform="translate(0.181818, 0.818182)"
                                  >
                                    <path
                                      d="M13.6742424,-6.75015599e-14 C22.3760331,-6.75015599e-14 27.3484848,9.94490358 27.3484848,9.94490358 C27.3484848,9.94490358 22.3760331,19.8898072 13.6742424,19.8898072 C4.97245179,19.8898072 1.0658141e-14,9.94490358 1.0658141e-14,9.94490358 C1.0658141e-14,9.94490358 4.97245179,-6.75015599e-14 13.6742424,-6.75015599e-14 Z M13.6742424,6.21556474 C11.6145855,6.21556474 9.94490358,7.88524661 9.94490358,9.94490358 C9.94490358,12.0045606 11.6145855,13.6742424 13.6742424,13.6742424 C15.7338994,13.6742424 17.4035813,12.0045606 17.4035813,9.94490358 C17.4035813,7.88524661 15.7338994,6.21556474 13.6742424,6.21556474 Z"
                                      id="Combined-Shape"
                                    ></path>
                                  </g>
                                </g>
                                <line
                                  x1="0.5"
                                  y1="15.5"
                                  x2="30.5"
                                  y2="15.5"
                                  id="Path-3"
                                  strokeWidth="1.5"
                                  transform="translate(15.863665, 15.476905) rotate(45.000000) translate(-15.863665, -15.476905) "
                                ></line>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      className="signInLabel"
                      htmlFor="ConfirmPassword"
                    >
                      Confirm Password
                    </label>
                    <input
                      disabled={disabled}
                      className="form_input"
                      data-val="true"
                      data-val-equalto="The password and confirmation password do not match."
                      data-val-equalto-other="*.Password"
                      data-val-required="The Confirm Password field is required."
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      type={toggleConfirmVis ? "text" : "password"}
                      value={registerState.confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      style={{
                        backgroundImage:
                          "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAABKRJREFUWAnNl0tsVGUUxzvTTlslZUaCloZHY6BRFkp9sDBuqgINpaBp02dIDImwKDG6ICQ8jBYlhg0rxUBYEALTpulMgBlqOqHRDSikJkZdGG0CRqAGUuwDovQ1/s7NPTffnTu3zMxGvuT2vP7n8Z3vu+dOi4r+5xUoJH8sFquamZmpTqfTVeIfCARGQ6HQH83NzaP5xsu5gL6+vuVzc3NdJN1Kkhd8Ev1MMYni4uJjra2tt3wwLvUjCxgYGFg8Pj7+MV5dPOUub3/hX0zHIpFId0NDw6Q/jO4tZOzv76+Znp6+AOb5TBw7/YduWC2Hr4J/IhOD/GswGHy7vb39tyw2S+VbAC1/ZXZ29hKoiOE8RrIvaPE5WvyjoS8CX8sRvYPufYpZYtjGS0pKNoD/wdA5bNYCCLaMYMMEWq5IEn8ZDof3P6ql9pF9jp8cma6bFLGeIv5ShdISZUzKzqPIVnISp3l20caTJsaPtwvc3dPTIx06ziZkkyvY0FnoW5l+ng7guAWnpAI5w4MkP6yy0GQy+dTU1JToGm19sqKi4kBjY+PftmwRYn1ErEOq4+i2tLW1DagsNGgKNv+p6tj595nJxUbyOIF38AwipoSfnJyMqZ9SfD8jxlWV5+fnu5VX6iqgt7d3NcFeUiN0n8FbLEOoGkwdgY90dnbu7OjoeE94jG9wd1aZePRp5AOqw+9VMM+qLNRVABXKkLEWzn8S/FtbdAhnuVQE7LdVafBPq04pMYawO0OJ+6XHZkFcBQA0J1xKgyhlB0EChEWGX8RulsgjvOjEBu+5V+icWOSoFawuVwEordluG28oSCmXSs55SGSCHiXhmDzC25ghMHGbdwhJr6sAdpnyQl0FYIyoEX5CeYOuNHg/NhvGiUUxVgfV2VUAxjtqgPecp9oKoE4sNnbX9HcVgMH8nD5nAoWnKM/5ZmKyySRdq3pCmDncR4DxOwVC64eHh0OGLOcur1Vey46xUZ3IcVl5oa4OlJaWXgQwJwZyhUdGRjqE14VtSnk/mokhxnawiwUvsZmsX5u+rgKamprGMDoA5sKhRCLxpDowSpsJ8vpCj2AUPzg4uIiNfKIyNMkH6Z4hF3k+RgTYz6vVAEiKq2bsniZIC0nTtvMVMwBzoBT9tKkTHp8Ak1V8dTrOE+NgJs7VATESTH5WnVAgfHUqlXK6oHpJEI1G9zEZH/Du16leqHyS0UXBNKmeOMf5NvyislJPB8RAFz4g8IuwofLy8k319fUP1EEouw7L7mC3kUTO1nn3sb02MTFxFpsz87FfJuaH4pu5fF+reDz+DEfxkI44Q0ScSbyOpDGe1RqMBN08o+ha0L0JdeKi/6msrGwj98uZMeon1AGaSj+elr9LwK9IkO33n8cN7Hl2vp1N3PcYbUXOBbDz9bwV1/wCmXoS3+B128OPD/l2LLg8l9APXVlZKZfzfDY7ehlQv0PPQDez6zW5JJdYOXdAwHK2dGIv7GH4YtHJIvEOvvunLCHPPzl3QOLKTkl0hPbKaDUvlTU988xtwfMqQBPQ3m/4mf0yBVlDCSr/CRW0CipAMnGzb9XU1NSRvIX7kSgo++Pg9B8wltxxbHKPZgAAAABJRU5ErkJggg==&quot;)",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "16px 18px",
                        backgroundPosition: "98% 50%",
                      }}
                    />
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-for="ConfirmPassword"
                        data-valmsg-replace="true"
                      ></span>
                    </span>
                    <div
                      onClick={toggleConfirmVisibility}
                      className="password-icon"
                    >
                      <div
                        className="togglePasswordShow"
                        style={toggleConfirmVis ? { display: "none" } : {}}
                      >
                        <svg
                          width="31px"
                          height="31px"
                          viewBox="0 0 31 31"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Templates"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              id="Contact-Us-template"
                              transform="translate(-455.000000, -411.000000)"
                              stroke="#141B4D"
                              strokeWidth="2"
                            >
                              <g
                                id="Icons/Show/On"
                                transform="translate(456.000000, 412.000000)"
                              >
                                <g
                                  id="eye"
                                  transform="translate(0.181818, 0.818182)"
                                >
                                  <path
                                    d="M13.6742424,-6.75015599e-14 C22.3760331,-6.75015599e-14 27.3484848,9.94490358 27.3484848,9.94490358 C27.3484848,9.94490358 22.3760331,19.8898072 13.6742424,19.8898072 C4.97245179,19.8898072 1.0658141e-14,9.94490358 1.0658141e-14,9.94490358 C1.0658141e-14,9.94490358 4.97245179,-6.75015599e-14 13.6742424,-6.75015599e-14 Z M13.6742424,6.21556474 C11.6145855,6.21556474 9.94490358,7.88524661 9.94490358,9.94490358 C9.94490358,12.0045606 11.6145855,13.6742424 13.6742424,13.6742424 C15.7338994,13.6742424 17.4035813,12.0045606 17.4035813,9.94490358 C17.4035813,7.88524661 15.7338994,6.21556474 13.6742424,6.21556474 Z"
                                    id="Combined-Shape"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <div
                        className="togglePasswordHide"
                        style={!toggleConfirmVis ? { display: "none" } : {}}
                      >
                        <svg
                          width="31px"
                          height="31px"
                          viewBox="0 0 31 31"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Templates"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Contact-Us-template"
                              transform="translate(-401.000000, -407.000000)"
                              stroke="#141B4D"
                            >
                              <g
                                id="Group-2"
                                transform="translate(401.000000, 407.000000)"
                              >
                                <g
                                  id="Icons/Show/On-Copy"
                                  transform="translate(2.000000, 5.000000)"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                >
                                  <g
                                    id="eye"
                                    transform="translate(0.181818, 0.818182)"
                                  >
                                    <path
                                      d="M13.6742424,-6.75015599e-14 C22.3760331,-6.75015599e-14 27.3484848,9.94490358 27.3484848,9.94490358 C27.3484848,9.94490358 22.3760331,19.8898072 13.6742424,19.8898072 C4.97245179,19.8898072 1.0658141e-14,9.94490358 1.0658141e-14,9.94490358 C1.0658141e-14,9.94490358 4.97245179,-6.75015599e-14 13.6742424,-6.75015599e-14 Z M13.6742424,6.21556474 C11.6145855,6.21556474 9.94490358,7.88524661 9.94490358,9.94490358 C9.94490358,12.0045606 11.6145855,13.6742424 13.6742424,13.6742424 C15.7338994,13.6742424 17.4035813,12.0045606 17.4035813,9.94490358 C17.4035813,7.88524661 15.7338994,6.21556474 13.6742424,6.21556474 Z"
                                      id="Combined-Shape"
                                    ></path>
                                  </g>
                                </g>
                                <line
                                  x1="0.5"
                                  y1="15.5"
                                  x2="30.5"
                                  y2="15.5"
                                  id="Path-3"
                                  strokeWidth="1.5"
                                  transform="translate(15.863665, 15.476905) rotate(45.000000) translate(-15.863665, -15.476905) "
                                ></line>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="form-group checkbox gdprConsent">
                    <p>Consent for storing submitted data.</p>
                    <label className="signInLabel">
                      <input
                        disabled={disabled}
                        className="gdpr-checkbox"
                        data-val="true"
                        data-val-required="The DataConsent field is required."
                        id="DataConsent"
                        name="DataConsent"
                        type="checkbox"
                        checked={registerState.consent}
                        onChange={(event) => handleConsentChange(event)}
                      />
                      <input
                        name="DataConsent"
                        type="hidden"
                        defaultValue="false"
                      />
                      <span className="me mt-0">
                        Yes, I give permission to store and process my data
                      </span>
                    </label>
                    <p className="text-small">
                      Learn more about how we store and use data: <br />{" "}
                      <a
                        className="text-underline"
                        href="https://www.edify.ac/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Click here to view our Privacy Policy
                      </a>
                    </p>
                    <span className="validationMessage">
                      <span
                        className="field-validation-error"
                        data-valmsg-for="DataConsent"
                        data-valmsg-replace="true"
                      >
                        {
                          errorState.filter(
                            (error) => error.field === "general"
                          )[0]?.error
                        }
                      </span>
                    </span>
                  </div>
                  <input
                    id="SubscriptionId"
                    name="SubscriptionId"
                    type="hidden"
                    defaultValue=""
                  />
                  <input
                    autoComplete="off"
                    id="SecondaryEmail"
                    name="SecondaryEmail"
                    type="hidden"
                    defaultValue=""
                  />
                  <div className="form-group submit-btn">
                    <Button
                      disabled={disabled}
                      variant="primary"
                      onClick={btnRegisterUserHandler}
                    >
                      {disabled ? (
                        <div className="register_loading">
                          <div className="loader rotate mt-3"></div>
                        </div>
                      ) : (
                        "Register"
                      )}
                    </Button>
                  </div>
                </form>
                <div className="registerAccount">
                  <p>
                    Already have an account?{" "}
                    <span>
                      <a href="/">Sign In &gt;</a>
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-md-7 mt-110 d-none d-md-block">
                <img
                  className="img-responsive"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/edify/contact-us-bg.png"
                  }
                  alt="contact us background"
                />
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </main>
    </>
  );
};

export default RegisterComponent;
