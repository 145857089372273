import { Action } from "redux";
import { UserActionTypes } from "./userActionTypes";
import { IUser } from "../../interfaces/IUser";

export interface UserAction extends Action<UserActionTypes> {
  data: any;
  error?: string;
}

export function userLoginStarted(): UserAction {
  return {
    type: UserActionTypes.USER_LOGIN_STARTED,
    data: null,
  };
}

export function userLoginSuccess(jwt_token: string): UserAction {
  return {
    type: UserActionTypes.USER_LOGIN_SUCCESS,
    data: jwt_token,
  };
}

export function userLoginError(err: any): UserAction {
  return {
    type: UserActionTypes.USER_LOGIN_ERROR,
    data: null,
    error: err,
  };
}

export function registerUserStarted(): UserAction {
  return {
    type: UserActionTypes.REGISTER_USER_STARTED,
    data: null,
  };
}

export function registerUserSuccess(): UserAction {
  return {
    type: UserActionTypes.REGISTER_USER_SUCCESS,
    data: null,
  };
}

export function registerUserError(err): UserAction {
  return {
    type: UserActionTypes.REGISTER_USER_ERROR,
    data: null,
    error: err,
  };
}

export function getUserStarted(): UserAction {
  return {
    type: UserActionTypes.GET_USER_STARTED,
    data: null,
  };
}

export function getUserSuccess(user: IUser): UserAction {
  return {
    type: UserActionTypes.GET_USER_SUCCESS,
    data: user,
  };
}

export function getUserError(err): UserAction {
  return {
    type: UserActionTypes.GET_USER_ERROR,
    data: null,
    error: err,
  };
}

export function userUpdateStarted(): UserAction {
  return {
    type: UserActionTypes.UPDATE_USER_STARTED,
    data: null,
  };
}

export function userUpdateSuccess(user: IUser): UserAction {
  return {
    type: UserActionTypes.UPDATE_USER_SUCCESS,
    data: user,
  };
}

export function userUpdateError(err: any): UserAction {
  return {
    type: UserActionTypes.UPDATE_USER_ERROR,
    data: null,
    error: err,
  };
}

export function userUploadRequestStarted(fileName: string): UserAction {
  return {
    type: UserActionTypes.GET_UPLOAD_TOKEN_STARTED,
    data: fileName,
  };
}

export function userUploadRequestSuccess(uploadToken: string): UserAction {
  return {
    type: UserActionTypes.GET_UPLOAD_TOKEN_SUCCESS,
    data: uploadToken,
  };
}

export function userUploadRequestError(err: any): UserAction {
  return {
    type: UserActionTypes.UPDATE_USER_ERROR,
    data: null,
    error: err,
  };
}
