import { Nav, Navbar} from "react-bootstrap";

const HeaderComponent = () => {
  return (
    <>
      <Navbar bg="white" expand="lg" fixed="top" className="px-5">
          <Navbar.Brand href="https://www.edify.ac"><img
            src={process.env.PUBLIC_URL + "/images/edify_logo.svg"}
            className="img-responsive"
            alt="edify-logo"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="main-navbar-nav" />
          <Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href="https://www.edify.ac/platform" className="px-2">Platform</Nav.Link>
              <Nav.Link href="https://www.edify.ac/case-studies" className="px-2">Case Studies</Nav.Link>
              <Nav.Link href="https://www.edify.ac/support" className="px-2">Support</Nav.Link>
              <Nav.Link href="https://www.edify.ac/about" className="px-2">About</Nav.Link>
              <Nav.Link href="https://www.edify.ac/blog" className="px-2">Blog</Nav.Link>
              <Nav.Link href="https://www.edify.ac/careers" className="px-2">Careers</Nav.Link>
              <Nav.Link href="https://www.edify.ac/contact" className="px-2">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HeaderComponent;
