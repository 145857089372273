import { useCallback, useEffect, useState } from "react";
import "./profileContainerComponent.scss";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Tab, Tabs } from "react-bootstrap";
import BillingComponent from "./BillingComponent";
import DetailsComponent from "./DetailsComponent";
import VirtualAvatarComponent from "./VirtualAvatarComponent";
import SidePanelComponent from "./SidePanelComponent";
import UserProfileNavBarComponent from "./UserProfileNavBarComponent";
import { IUser } from "../../interfaces/IUser";
import {
  getUserStarted,
  getUserError,
  getUserSuccess,
  userLoginStarted,
  userLoginSuccess,
  userLoginError,
} from "../../state/user/userActions";
import { IServiceResponse } from "../../interfaces/IServiceReponse";
import AccountService from "../../services/AccountService";
import {
  getApplicationsError,
  getApplicationsStarted,
  getApplicationsSuccess,
} from "../../state/application/applicationActions";
import ApplicationService from "../../services/ApplicationService";
import { IApplication } from "../../interfaces/IApplication";
import FullPageLoader from "../fullPageLoader/FullPageLoader";

const ProfileContainerComponent = () => {
  const hubAppId = process.env.REACT_APP_HUB_APP_ID!;
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [hubDownloadUrl, setHubDownloadUrl] = useState("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [account, setAccount] = useState<IUser>();

  const handleAccountUpdate = (account) => {
    setAccount(account);
  };

  const getAccessToken = async () => {
    let token: string | undefined;

    try {
      dispatch(userLoginStarted());

      token = await getAccessTokenSilently();

      setAccessToken(token);

      dispatch(userLoginSuccess(token));
    } catch (err) {
      const errMessage = `Failed to get access token. Error: ${err}`;
      dispatch(userLoginError(errMessage));
    }

    return token;
  };

  useEffect(() => {
    if (!isAuthenticated || !accessToken) {
      getAccessToken();
    } else {
      getAccount();
      getHubDownloadUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, accessToken]);

  const getAccount = useCallback(async () => {
    if (accessToken) {
      dispatch(getUserStarted());
      try {
        const accountService = new AccountService(accessToken);
        const response = await accountService.getUser();
        const serviceResponse: IServiceResponse = response.data;
        const user: IUser = {
          AccountId: serviceResponse.data["accountId"],
          FirstName: serviceResponse.data["firstName"],
          LastName: serviceResponse.data["lastName"],
          Email: serviceResponse.data["email"],
          OrganisationId:
            serviceResponse.data["organisation"]?.["organisationId"],
          OrganisationName:
            serviceResponse.data["organisation"]?.["organisationName"],
          ProfilePictureUrl: serviceResponse.data["profilePictureUrl"],
          AvatarUrl: serviceResponse.data["avatarUrl"],
          LicenceTypeName: serviceResponse.data["licence"]["licenceTypeName"],
          NextInvoiceDate: serviceResponse.data["licence"]["nextInvoiceDate"],
          IsNonExpiring: serviceResponse.data["licence"]["isNonExpiring"],
          Created: serviceResponse.data["created"],
          Suspended: serviceResponse.data["suspended"],
          Archived: serviceResponse.data["archived"],
          Token: accessToken,
        };
        setAccount(user);
        dispatch(getUserSuccess(user));
      } catch (error) {
        dispatch(getUserError(error));
        //need to handle error here like redirect to login?
        //or failed to get profile message
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]); // if token changes, callback will run again to get the account info

  //Gets the latest version of the Edify Hub and sets the download link
  const getHubDownloadUrl = async () => {
    if (accessToken) {
      dispatch(getApplicationsStarted());
      try {
        const applicationService = new ApplicationService(accessToken);
        const response = await applicationService.getApplications(
          hubAppId,
          "ascending",
          true
        );
        const serviceResponse: IServiceResponse = response.data;
        const applications: IApplication[] = serviceResponse.data;
        //Get the most up-to-date application release
        const hub: IApplication = applications.filter((x) => x.isLatest)[0];
        setHubDownloadUrl(hub.downloadURL);
        dispatch(getApplicationsSuccess(applications));
      } catch (error) {
        dispatch(getApplicationsError(error));
      }
    }
  };

  return (
    <>
      {account ? (
        <>
          <UserProfileNavBarComponent hubDownloadUrl={hubDownloadUrl} />
          <main className="main-profile-container mt-67">
            <SidePanelComponent account={account} />
            <div className="holder">
              <div className="centering">
                <div className="container container-profile-dashboard">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="profile-heading">
                        <h3 className="profile-details">Profile</h3>
                      </div>
                      <div>
                        <Tabs id="mainTabs" className="mb-1">
                          <Tab eventKey="details" title="Details">
                            <DetailsComponent
                              accountDetail={account}
                              updateAccount={handleAccountUpdate}
                            />
                          </Tab>
                          <Tab eventKey="virtualAvatar" title="Virtual Avatar">
                            <VirtualAvatarComponent
                              account={account}
                              updateAccount={handleAccountUpdate}
                            />
                          </Tab>
                          <Tab eventKey="billing" title="Billing">
                            <BillingComponent
                              isNonExpiring={account.IsNonExpiring}
                              licenceType={account.LicenceTypeName}
                              licenceExpiry={account.NextInvoiceDate}
                            />
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <FullPageLoader />
      )}
    </>
  );
};

export default ProfileContainerComponent;
