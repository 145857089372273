import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import LogoutButton from "../logoutButton/LogoutButton";

const UserProfileNavBarComponent = ({ hubDownloadUrl }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(hubDownloadUrl);
  }, [hubDownloadUrl]);

  return (
    <>
      <Navbar fixed="top" bg="white" expand="md" className="px-5">
        <Navbar.Brand href="/">
          <img
            src={process.env.PUBLIC_URL + "/images/edify_logo.svg"}
            className="img-responsive"
            alt="Edify Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
          <Nav className="me-auto p-2">
            <Nav className="px-2 my-2">
              <a href={url} className="btn btn-secondary">
                Download Edify
              </a>
            </Nav>
            <Nav className="px-2 my-2 d-sm-block d-md-none">
              <LogoutButton className="btn btn-secondary" />
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default UserProfileNavBarComponent;
