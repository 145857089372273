import Moment from "react-moment";

const BillingComponent = ({ licenceType, licenceExpiry, isNonExpiring }) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <h4 className="billing-header">{licenceType}</h4>
            <span>
              <label className="profile-label">Your licence expires on:</label>
            </span>
            <h4 className="billing-header">
              {isNonExpiring ? (
                "∞"
              ) : (
                <Moment format="D MMM YYYY">{licenceExpiry}</Moment>
              )}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingComponent;
