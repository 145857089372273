import { useAuth0, LogoutOptions } from '@auth0/auth0-react';

function LogoutButton({ className = '' }) {
    const { isAuthenticated, logout } = useAuth0();

    const handleLogout = (evt) => {
        console.log("logout request initiated...");

        const auth0LogoutOptions: LogoutOptions = {
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI!,
            federated: false,
            localOnly: false,
        };

        logout(auth0LogoutOptions);
    };

    return (
        <>
            {
                isAuthenticated && (
                    <button className={className} onClick={handleLogout}>
                        Logout
                    </button>
                )
            }
        </>
    )
}

export default LogoutButton