import { useEffect, useRef, useState } from "react";
import { IUser } from "../../interfaces/IUser";
import { useDispatch } from "react-redux";
import {
  userUpdateError,
  userUpdateStarted,
  userUpdateSuccess,
  userUploadRequestStarted,
  userUploadRequestError,
  userUploadRequestSuccess,
} from "../../state/user/userActions";
import AccountService from "../../services/AccountService";
import { IServiceResponse } from "../../interfaces/IServiceReponse";
import axios from "axios";

const DetailsComponent = ({ accountDetail, updateAccount }) => {
  type File = {
    path: Blob;
    name: string;
  };
  const dispatch = useDispatch();
  const inputFile = useRef<HTMLInputElement>(null);
  const [account, setAccount] = useState(accountDetail);
  const [displayImg, setDisplayImg] = useState<string>();
  const [imgLocal, setImgLocal] = useState(false);
  const [newPicture, setNewPicture] = useState<File | null>();
  const [uploadUrl, setUploadUrl] = useState<string | null>();
  const [changes, setChanges] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [errorState, setErrorState] = useState({
    FirstName: false,
    LastName: false,
  });

  useEffect(() => {
    setChanges(JSON.stringify(account) !== JSON.stringify(accountDetail));
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    let path = account.ProfilePictureUrl;
    if (!imgLocal) {
      path = `${path}?${new Date().getTime()}`;
    }
    setDisplayImg(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.ProfilePictureUrl]);

  useEffect(() => {
    if (newPicture) {
      getUploadToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPicture]);

  const handleChange = (event) => {
    setAccount({ ...account, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file: File = {
      path: event.target.files[0],
      name: event.target.files[0].name,
    };
    setNewPicture(file);
    setAccount({
      ...account,
      ProfilePictureUrl: URL.createObjectURL(event.target.files[0]),
    });
    setImgLocal(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!errorState.FirstName && !errorState.LastName) {
      const updated: IUser = account;
      let imageUplaoded = await uploadProfilePicture();
      if (imageUplaoded && uploadUrl) {
        updated.ProfilePictureUrl = uploadUrl.split("?")[0];
      }
      updateUser(updated);
    } else {
      setFormInvalid(true);
      console.log("Form invalid");
    }
  };

  const onBrowseClick = () => {
    if (inputFile.current != null) {
      inputFile.current.click();
    }
  };

  const onRemoveImage = () => {
    if (inputFile.current?.value != null) {
      inputFile.current.value = "";
      setNewPicture(null);
      setAccount({ ...account, ProfilePictureUrl: null });
    }
  };

  const validate = () => {
    setErrorState({
      ...errorState,
      FirstName: account.FirstName.trim().length === 0,
      LastName: account.LastName.trim().length === 0,
    });
  };

  const onUpdateAccountSuccess = () => {
    //alert account update successful
    alert("Account details updated.");
  };

  const onUpdateAccountError = () => {
    //alert account update error
    alert("Could not update account details.");
  };

  const updateUser = async (account: IUser) => {
    if (account) {
      setFormInvalid(false);
      dispatch(userUpdateStarted());
      try {
        const accountService = new AccountService(account.Token);
        const response = await accountService.updateUser(account);
        const serviceResponse: IServiceResponse = response.data;
        if (serviceResponse.success) {
          dispatch(userUpdateSuccess(account));
          setChanges(false);
          updateAccount(account);
          onUpdateAccountSuccess();
        } else {
          dispatch(userUpdateError("Error updating user details."));
          onUpdateAccountError();
        }
      } catch (error) {
        dispatch(userUpdateError(`Error updating user details: ${error}`));
        onUpdateAccountError();
      }
    }
  };

  const getUploadToken = async () => {
    if (newPicture) {
      dispatch(userUploadRequestStarted(newPicture.name));
      try {
        const accountService = new AccountService(account.Token);
        const response = await accountService.getUploadToken(newPicture.name);
        const serviceResponse: IServiceResponse = response.data;
        if (serviceResponse.success) {
          dispatch(userUploadRequestSuccess(serviceResponse.data));
          setUploadUrl(serviceResponse.data);
        } else {
          dispatch(userUploadRequestError("Error uploading profile picture."));
        }
      } catch (error) {
        dispatch(
          userUploadRequestError(`Error uploading profile picture: ${error}`)
        );
      }
    }
  };

  const uploadProfilePicture = async () => {
    if (newPicture) {
      if (uploadUrl) {
        try {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(newPicture.path);
          return (fileReader.onloadend = async () => {
            const data = fileReader.result;
            axios
              .put(uploadUrl, data, {
                headers: { "x-ms-blob-type": "BlockBlob" },
              })
              .then(function (response) {
                return true;
              })
              .catch(function (error) {
                console.log(`Failed to upload image: ${error}`);
              })
              .finally(function () {
                updateAccount(account);
              });
            return false;
          });
        } catch (error) {
          console.log(`Failed to upload image: ${error}`);
        }
      }
      console.log("failed to upload Profile Picture.");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-3">
          <div className="mx-auto profile-picture">
            <div className="">
              <div className="dropzone">
                <input
                  type="file"
                  id="pictureSelector"
                  name="ProfilePicture"
                  ref={inputFile}
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <div
                  className={
                    account.ProfilePictureUrl ? "dropzone dz-started" : ""
                  }
                >
                  <div className="dz-default dz-message">
                    <button
                      className="dz-button"
                      type="button"
                      onClick={onBrowseClick}
                    >
                      Select image to upload
                    </button>
                  </div>
                  <div className="dz-image">
                    {account.ProfilePictureUrl && (
                      <img alt={displayImg} src={displayImg} />
                    )}
                  </div>
                </div>
              </div>
              <span
                hidden={!account.ProfilePictureUrl}
                className="delete-btn profile-remove"
                onClick={onRemoveImage}
              >
                Remove
              </span>
              <input type="hidden" name="newMedia" className="js-new-media" />
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-xl-6 col-no-gutters">
          <div id="js-profile-page" className="form-edit-profile">
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
            >
              <input
                id="Id"
                name="Id"
                type="hidden"
                value="80ba870a-21ef-40a4-972c-035c1353b93f"
              />
              <input id="photo-id" name="PhotoId" type="hidden" value="0" />{" "}
              <div className="col-lg-12">
                <h3 className="col-lg-6 profile-details">Details</h3>
                <span
                  className={
                    changes
                      ? "col-lg-6 unsaved-message unsaved-show"
                      : "col-lg-6 unsaved-message"
                  }
                >
                  <span className="alert alert-dismissable alert-info">
                    You have unsaved changes.
                  </span>
                </span>

                <div className="form-group">
                  <label className="profile-label">First name</label>
                  <input
                    className={
                      errorState.FirstName && formInvalid
                        ? "input-validation-error form_input"
                        : "form_input"
                    }
                    id="FirstName"
                    name="FirstName"
                    placeholder="Enter your first name"
                    type="text"
                    value={account.FirstName}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className="profile-label">Last name</label>
                  <input
                    className={
                      errorState.LastName && formInvalid
                        ? "input-validation-error form_input"
                        : "form_input"
                    }
                    id="LastName"
                    name="LastName"
                    placeholder="Enter your last name"
                    type="text"
                    value={account.LastName}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className="profile-label">E-mail</label>
                  <input
                    className="form_input"
                    id="Email"
                    name="Email"
                    placeholder="Email@example.com"
                    type="text"
                    disabled={true}
                    value={account.Email}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className="profile-label">Organisation</label>
                  <input
                    className="form_input"
                    id="Organisation"
                    name="Organisation"
                    placeholder="Organisation"
                    type="text"
                    disabled={true}
                    value={account.OrganisationName}
                  />
                </div>
                <div className="form-edit-profile__actions">
                  <div className="submit-btn">
                    <input
                      className="btn btn-primary _submit"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
              </div>
            </form>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsComponent;
