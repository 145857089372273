import { React } from "react";

const FooterComponent = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xs-12 footer-col-spacing">
              <a className="navbar-brand" href="/">
                <img
                  className="featurette-image img-fluid mx-auto d-block"
                  src={
                    process.env.PUBLIC_URL + "/images/edify-logo-negative.png"
                  }
                  alt="edify"
                />
                <span className="beta">beta</span>
              </a>
            </div>
            <div className="col-md-4 col-xs-12 footer-col-spacing footer-nav-links">
              <ul className="list-unstyled">
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/product"
                  >
                    Product
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/case-studies"
                  >
                    Case Studies
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/request-demo"
                  >
                    Request Demo
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/support"
                  >
                    Support
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a className="footer-link" href="https://www.edify.ac/about">
                    About
                  </a>
                </li>

                <hr className="clearfix w-100 footer-hr" />

                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a className="footer-link" href="https://www.edify.ac/eula">
                    EULA
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/acceptable-use"
                  >
                    Acceptable Use
                  </a>
                </li>
                <li className="footer-item footer-links-col-1">
                  <a
                    className="footer-link"
                    href="https://www.edify.ac/accessibility"
                  >
                    Accessibility
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-xs-12 footer-col-spacing">
              <h5 className="text-capitalized">Connect with us</h5>
              <a className="mb-4 footer-email" href="mailto: hello@edify.ac">
                hello@edify.ac
              </a>
              <ul className="list-inline social-icons">
                <li className="py-1 list-inline-item">
                  <a
                    href="https://www.linkedin.com/company/edify-ac/"
                    className="fab fa-lg fa-linkedin"
                    aria-hidden="true"
                  >
                    LinkedIn
                  </a>
                </li>
                <li className="py-1 list-inline-item">
                  <a
                    href="https://www.facebook.com/edifyac/"
                    className="fab fa-lg fa-facebook-square"
                    aria-hidden="true"
                  >
                    Facebook
                  </a>
                </li>
                <li className="py-1 list-inline-item">
                  <a
                    href="https://twitter.com/edify_ac"
                    className="fab fa-lg fa-twitter-square"
                    aria-hidden="true"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row footer-copyright">
            <div className="col-md-2 mr-auto">
              <div>
                <span>© edify 2022</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
