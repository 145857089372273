import React, { useEffect, useState } from "react";

const ProfileImageComponent = (props) => {
  const [img, setImg] = useState();

  useEffect(() => {
    setImg(`${props.account.ProfilePictureUrl}?${new Date().getTime()}`);
  }, [props.account]);

  if (props.account.ProfilePictureUrl) {
    return (
      <>
        <img className="avatar" src={img} alt="" />;
      </>
    );
  } else {
    return (
      <>
        <div className="avatar-letter">
          {Array.from(props.account.FirstName)[0]}
        </div>
        ;
      </>
    );
  }
};

export default ProfileImageComponent;
