import "./registerSuccessComponent.scss"
import { MouseEvent, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function RegisterSuccessComponent() {
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState<number>(10);

  const btnLoginUserHandler = async (event: MouseEvent) => {
    navigate("/user/profile");
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user/profile");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (timeLeft === 0) {
      navigate("/user/profile")
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div className="regSuccess-container">
      <img alt="Edify"
        src={process.env.PUBLIC_URL + "/images/edify-logo-negative.png"}
        height="68"></img>
      <p className="title">You have been successfully registered.</p>
      <p className="subtitle">We have sent an activation link to your email.</p>
      <div>
        <span>You will automatically be redirected to a login page in </span><span className='countdown'>{String(timeLeft).padStart(2, '0')}</span><span> seconds, or you can click the login button below</span>
      </div>
      <br />
      <Button variant="secondary" onClick={btnLoginUserHandler}>Login</Button>
    </div>
  )
}

export default RegisterSuccessComponent;