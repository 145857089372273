export enum UserActionTypes {
  USER_LOGIN_STARTED = "USER_LOGIN_STARTED",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_ERROR = "USER_LOGIN_ERROR",
  REGISTER_USER_STARTED = "REGISTER_USER_STARTED",
  REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS",
  REGISTER_USER_ERROR = "REGISTER_USER_ERROR",
  GET_USER_STARTED = "GET_USER_STARTED",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_ERROR = "GET_USER_ERROR",
  UPDATE_USER_STARTED = "UPDATE_USER_STARTED",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
  GET_UPLOAD_TOKEN_STARTED = "GET_UPLOAD_TOKEN_STARTED",
  GET_UPLOAD_TOKEN_SUCCESS = "GET_UPLOAD_TOKEN_SUCCESS",
  GET_UPLOAD_TOKEN_ERROR = "GET_UPLOAD_TOKEN_ERROR",
}
