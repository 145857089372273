import { useEffect } from 'react';

const useScript = (parentElementId, scriptUrl, loadAsync) => {
    useEffect(() => {
      
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = loadAsync;

        const parentElement = document.getElementById(parentElementId);
        if (parentElement) {
            parentElement.appendChild(script);
        }
        else {
            console.error(`Unable to find parent element: ${ parentElementId }`);
        }

        return () => {
            if (parentElement) {
                parentElement.appendChild(script);
            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptUrl]);
}

export default useScript;