import axios, { Axios } from "axios";

class HttpService {
  baseUrl: string;
  contentType: string = "application/json";
  http: Axios;

  constructor(baseUrl: string, accessToken: string | null) {
    this.baseUrl = baseUrl;

    this.http = axios.create({
      baseURL: this.baseUrl,

      headers: {
        "Content-type": this.contentType,
      },
      
    });

    this.http.interceptors.request.use(function (config) {
      if (config.headers === undefined) {
        config.headers = {};
      }

      if (
        accessToken !== null &&
        accessToken !== undefined &&
        accessToken.length !== 0
      ) {
        config.headers.Authorization = "bearer " + accessToken;
      }
      return config;
    });
  }
}

export default HttpService;
