import { UserAction } from "./userActions";
import { UserActionTypes } from "./userActionTypes";
import { IUser } from "../../interfaces/IUser";

export interface UserState {
  data: IUser;
  error?: string;
  loading: boolean;
}

const initialState: UserState = {
  data: {
    AccountId: "",
    FirstName: "",
    LastName: "",
    Email: "",
    OrganisationId: null,
    OrganisationName: null,
    ProfilePictureUrl: null,
    AvatarUrl: null,
    LicenceTypeName: "",
    NextInvoiceDate: "",
    IsNonExpiring: false,
    Created: null,
    Suspended: false,
    Archived: false,
    Token: "",
  },
  loading: false,
  error: undefined,
};

export function userReducer(
  state: UserState = initialState,
  action: UserAction
): UserState {
  switch (action.type) {
    case UserActionTypes.REGISTER_USER_STARTED:
      return { data: { ...state.data }, loading: true, error: undefined };

    case UserActionTypes.REGISTER_USER_SUCCESS:
      // Do not replace token. This will be changed for new login flow.
      return { data: { ...state.data }, loading: false, error: undefined };

    case UserActionTypes.REGISTER_USER_ERROR:
      return { data: { ...state.data }, loading: false, error: action.error };

    case UserActionTypes.USER_LOGIN_STARTED:
      return { data: { ...state.data }, loading: true, error: undefined };

    case UserActionTypes.USER_LOGIN_SUCCESS:
      return {
        data: { ...state.data, Token: action.data },
        loading: false,
        error: undefined,
      };

    case UserActionTypes.USER_LOGIN_ERROR:
      return { data: { ...state.data }, loading: false, error: action.error };

    case UserActionTypes.GET_USER_STARTED:
      return { data: { ...state.data }, loading: true, error: undefined };

    case UserActionTypes.GET_USER_SUCCESS:
      return { data: action.data, loading: false, error: undefined };

    case UserActionTypes.GET_USER_ERROR:
      return { data: { ...state.data }, loading: false, error: action.error };

    case UserActionTypes.UPDATE_USER_STARTED:
      return { data: { ...state.data }, loading: true, error: undefined };

    case UserActionTypes.UPDATE_USER_SUCCESS:
      return { data: action.data, loading: true, error: undefined };

    case UserActionTypes.UPDATE_USER_ERROR:
      return { data: { ...state.data }, loading: false, error: action.error };

    case UserActionTypes.GET_UPLOAD_TOKEN_STARTED:
      return { data: { ...state.data }, loading: true, error: undefined };

    case UserActionTypes.GET_UPLOAD_TOKEN_SUCCESS:
      // This should set upload token and not replace state
      return { data: { ...state.data }, loading: false, error: undefined };

    case UserActionTypes.GET_UPLOAD_TOKEN_ERROR:
      return { data: { ...state.data }, loading: false, error: action.error };

    default:
      return state;
  }
}
