import { Action } from "redux";
import { IApplication } from "../../interfaces/IApplication";
import { ApplicationActionTypes } from "./applicationActionTypes";

export interface ApplicationActions extends Action<ApplicationActionTypes> {
  data: any;
  error?: string;
}

export function getApplicationsStarted(): ApplicationActions {
  return {
    type: ApplicationActionTypes.GET_APPLICATIONS_STARTED,
    data: null,
  };
}

export function getApplicationsSuccess(
  applications: IApplication[]
): ApplicationActions {
  return {
    type: ApplicationActionTypes.GET_APPLICATIONS_SUCCESS,
    data: applications,
  };
}

export function getApplicationsError(err): ApplicationActions {
  return {
    type: ApplicationActionTypes.GET_APPLICATIONS_ERROR,
    data: null,
    error: err,
  };
}
